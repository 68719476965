/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* SAIC Pallette */
$black: #000000;
$white: #ffffff;
$SAICGrey: #e1e1e1;
$SAICGreen: #bcd63e;
$SAICBlue: #006bb5;
$SAICDarkGrey: #64696f;

/* Type Face */
$titleFont: Inter;
$tableFont: Rift;
@import url("https://fonts.google.com/specimen/Inter");
@import url("https://fonts.adobe.com/fonts/rift");

/* Containers */
$appBackgroundPostion: center center;
$appBackgroundRepeat: no-repeat;
$appBackgroundHeight: 910px;
$appBackgroundColor: $SAICDarkGrey;

/* App Bar Header */
$headerBackgroundColor: linear-gradient(
  to right,
  transparent,
  #006bb6,
  #00acd4
);
$SAICHeaderImg: 50px;

// Side Drawer
$drawerBackgroundColor: #343434;
$drawerIconColor: $SAICGrey;

/* Search Page */
$searchPageContainerMargin: 35px auto;
$searchBoxBorderRadius: 8px;
$searchBoxShadow: 1px 3.5px 4.5px 0px #b4bbc5;
$searchBoxBackground: $white;
$searchBoxMarginLeft: 350px;

/* Search Button */
$searchButtonBackgroundColor: $SAICGreen;
$searchButtonBorderRadius: 15px;
$searchButtonHeight: 45px;
$searchButtonMarginTop: 5px;
