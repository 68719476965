@import "./variables.scss";

@media (max-width: 672px) {
  .searchContainer {
    margin: $searchPageContainerMargin;
    background: $SAICGrey;
  }

  .searchBox {
    border-radius: $searchBoxBorderRadius;
    box-shadow: $searchBoxShadow;
    background-image: linear-gradient(to right, #ffffff, #ffffff, #f1f9ff);
    border-left: 2px $SAICBlue solid;
    margin: 0 auto;
  }

  .searchButton {
    background-color: $searchButtonBackgroundColor;
    border-radius: 5px;
    height: 25px;
    width: 5px;
    margin-bottom: -50px;
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-right: 150px;
  }

  // Side Menu
  @media (max-width: 1000px) {
    /* Side Menu */
    .hideMenu {
      display: none;
    }
  }
}
