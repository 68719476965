@import "./variables.scss";

@media (min-width: 768px) {
  .searchBox {
    border-radius: $searchBoxBorderRadius;
    box-shadow: $searchBoxShadow;
    background-image: linear-gradient(to right, #ffffff, #ffffff, #f1f9ff);
    border-left: 15px $SAICBlue solid;
    margin: 0 auto;
    margin-left: -150px;
  }

  /* Search Page */
  .searchContainer {
    margin: $searchPageContainerMargin;
    background: transparent;
  }
}

// Side Menu
@media (max-width: 1079px) {
  /* Side Menu */
  .hideMenu {
    display: none;
  }

  .responseSection {
    width: 85%;
    height: 100%;
    background: $white;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-left: 100px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
  }

  .responseAnswer {
    width: 85%;
    height: 100%;
    background: $white;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-left: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    box-shadow: 0 3px 3px rgb(0 0 0 / 0.2);
  }

  .searchBox {
    border-radius: $searchBoxBorderRadius;
    box-shadow: $searchBoxShadow;
    background-image: linear-gradient(to right, #ffffff, #ffffff, #f1f9ff);
    border-left: 2px $SAICBlue solid;
    margin-right: -50px;
  }

  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    width: 55%;
  }

  .css-1p6dq0-MuiFormControl-root-MuiTextField-root {
    width: 55%;
  }
}

@media (max-width: 1080px) {
  .searchBox {
    margin-left: 355px;
  }
}
