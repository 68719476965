@import "./variables.scss";

body {
  margin: 0;
  background: $SAICGrey;
}

ul,
li {
  list-style: none;
}

/* Side Menu */
.hideMenu {
  display: block;
}

.appContainer {
  background-position: $appBackgroundPostion;
  background-repeat: $appBackgroundRepeat;
  height: $appBackgroundHeight;
}

// Header Bar
.MuiAppBar-root {
  background-color: $headerBackgroundColor;
}
.headerContainer img {
  height: $SAICHeaderImg;
}

// Side Drawer
.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper {
  background-color: $drawerBackgroundColor;
}
.css-i4bv87-MuiSvgIcon-root {
  color: $drawerIconColor;
}

/* Search Page */
.searchContainer {
  margin: $searchPageContainerMargin;
  background: $SAICGrey;
}

.searchBox {
  border-radius: $searchBoxBorderRadius;
  box-shadow: $searchBoxShadow;
  background-image: linear-gradient(to right, #ffffff, #ffffff, #f1f9ff);
  border-left: 1px $SAICBlue solid;
  margin: 0 auto;
}

.searchButton {
  background-color: $searchButtonBackgroundColor;
  border-radius: $searchButtonBorderRadius;
  height: $searchButtonHeight;
  width: 40px;
  margin-top: $searchButtonMarginTop;
  margin-right: 5px;
  padding-right: 5px;
}

@media (min-width: 1615px) {
  .searchBox {
    margin-left: 155px;
  }
}
