@import "./variables.scss";

// Spacing
.spacer {
  background: transparent;
  width: 100%;
  height: 20px;
}
.divider {
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #98a0a6,
    transparent
  );
}

.dividerShape {
  margin: 0 auto;
  align-items: center;
  width: 75%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #95d0fb,
    transparent
  );
}

.chatDividerShape {
  margin: 0 auto;
  align-items: center;
  width: 50%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    #95d0fb,
    transparent
  );
}

// Alignment
.marginCenter {
  margin-left: o auto;
}

// Response Tracker
#responseList {
  list-style: none;
  padding: 5px;
  color: $white;
  background-color: $SAICDarkGrey;
  cursor: pointer;
}

.responseSection {
  width: 82%;
  height: 100%;
  background: $white;
  display: flex;
  align-items: left;
  justify-content: left;
  margin-left: 174px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
  //margin-top: 125px;
}

.responseAnswer {
  width: 82%;
  height: 100%;
  background: $white;
  display: flex;
  align-items: left;
  justify-content: left;
  margin-left: 174px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  border-bottom: $SAICBlue solid 3px;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
}

// Animations

.fadePanel {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// loading
.loadingContainer {
  width: 75%;
  margin: 100px auto;
  align-items: center;
  text-align: center;
  padding: 10px;
  z-index: 1;
  border-radius: 25px;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 26px;
  margin: 0.19em;
  background: #007db6;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading h2 {
  color: $SAICBlue;
  font-size: 16px;
}

.loading span:nth-of-type(2) {
  background: #016bb5;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #1c91e6;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #52b5fc;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #82cbff;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #82cbff;
  animation-delay: 1s;
}
.loading span:nth-of-type(7) {
  background: #b6c743;
  animation-delay: 1.2s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Avatar
.gradientUserAvatar {
  background: linear-gradient(130deg, #94c9ef, #2e98e3, #006bb5);
  background-size: 200% 200%;

  -webkit-animation: AvatarAnimation 5s ease infinite;
  -moz-animation: AvatarAnimation 5s ease infinite;
  animation: AvatarAnimation 5s ease infinite;
}

.gradientChatAvatar {
  background: linear-gradient(130deg, #d7ed68, #b1cb33, #a2bc1f);
  background-size: 200% 200%;

  -webkit-animation: AvatarAnimation 5s ease infinite;
  -moz-animation: AvatarAnimation 5s ease infinite;
  animation: AvatarAnimation 5s ease infinite;
}

@-webkit-keyframes AvatarAnimation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes AvatarAnimation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes AvatarAnimation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

/* Bubble Boxes */
.disclaimerBubble {
  font-family: arial, sans-serif;
  font-size: 1.1em;
  color: #fff;
  background: $SAICBlue;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  max-width: 320px;
  font-size: 18px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.disclaimerBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.disclaimerBubble::after {
  border-top-color: $SAICBlue;
  border-bottom: 0;
  bottom: -15px;
  left: 50%;
  margin-left: -20px;
}

.saveBubble {
  word-break: break-word;
  font-family: arial, sans-serif;
  font-size: 12px;
  color: $SAICDarkGrey;
  background: $SAICGreen;
  position: relative;
  padding: 20px;
  border-radius: 7px;
  width: 100px;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.saveBubble::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  color: #fff;
}

.bottom.saveBubble::after {
  border-top-color: $SAICGreen;
  border-bottom: 0;
  bottom: -15px;
  left: 50%;
  margin-left: -20px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Floating */
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin: 0 auto;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/* Ripple Animation */
.lds-ripple {
  display: inline-block;
  // position: relative;
  width: 35px;
  height: 35px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -1s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

/* Typewriter animation */

.typewriter span {
  color: #333;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

